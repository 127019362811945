import React, {useState, useEffect} from 'react'
import {
  Anchor,
  Box,
  Button,
  CheckBox,
  Heading,
  Layer,
  List,
  Text,
  Select,
  Image,
  ThemeContext
} from 'grommet'
import {Close, View} from 'grommet-icons'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash';

// application imports
import Divider from 'granite/components/Divider'
import Tooltip from 'granite/components/Tooltip'
import {defaultDays} from 'pages/components/FullCalendar/utils'

import MultiSelectDropDown from './employeeMultiselectDropdown';

function getDayLabels(values) {
  const mappedDays = []
  defaultDays.forEach(day => {
    const isSelected = values.includes(parseInt(day.val))
    if (isSelected) mappedDays.push(day.label)
  })
  return mappedDays.join(', ')
}
const ShiftModal = ({
  messages,
  data,
  layer,
  setData,
  shifts,
  removeEmployee,
  addEmployee,
  employees,
  setShiftEditData,
  allowShiftEdit = true,
  shiftData,
  employeesData,
  setLayer,
  setselectEmployee,
  shiftStartDate,
  shiftEndDate,
  setShiftDataForRecurringShift,
  setShiftData,
  date_format,
  time_format_24_hr,
  removeEmployeeFromRecurringShift,
  addEmployeeToRecurringShift,
  shiftManagement,
  isSubmitting,
  setSubmitShift,
  isEditing,
  setShiftStartEndDateNull,
  isAssignButtonLoading,
  selectedEmployees,
  setSelectedEmployees
}) => {
  const {shiftId} = data
  let selectedEmployee=[]
  const shift = shifts && shifts.length && shifts.find(shift => shift.id == shiftId)
  const [options, setOptions] = useState([])
  const [sortedEmployees,setSortedEmployees] = useState([])
  const [selectAllOptions, setSelectAllOptions] = useState([])
  const [isSearch, setIsSearch] = useState()
  const [selectedEmployeepks, setSelectedEmployeepks] = useState([])
  useEffect(() => {
      employees && employees.length && setOptions(employees.map((item) =>{
      const userData = {...item.user}
      return {...userData,...item}
    }))
  }, [employees])
  let editable = false

  useEffect(()=>{
      employees && employees.length && shiftData && shiftData.employee_ids && shiftData.employee_ids.length && shiftData.employee_ids.map((item)=>{
        if(employees.find(e => e.id == item)){
          selectedEmployee.push(employees.find(e => e.id == item))
        }        
      })
      setSortedEmployees(selectedEmployee.sort(sortEmployeeList))
      setIsSearch(false)
  },[shiftData && shiftData.employee_ids])

  // NOTE: past events are not editable
  if (shift) {editable = (!moment(Date.now()).isAfter(shiftStartDate))}
    
  const closeModal = (type) => {
    employees && employees.length && setOptions(employees.map((item) =>{
      const userData = {...item.user}
      return {...userData,...item}
    }))
    setData({shiftId: null})
    if(type == 'cross') {
      setShiftData(null)
      setShiftStartEndDateNull && setShiftStartEndDateNull()
    }
  }
  
  const sortEmployeeList = (a,b) => {
    const employee1 = a.user && a.user.first_name !== null ? a.user.first_name.toUpperCase() :  null;
    const employee2 = b.user && b.user.first_name !== null ? b.user.first_name.toUpperCase() : null;

    let comparison = 0;
    if (employee1 > employee2) {
      comparison = 1;
    } else if (employee1 < employee2) {
      comparison = -1;
    }
    return comparison;
  }

  useEffect(() => {
    const items = [
      { full_name: 'Select All', id: null },
      ...options,
    ];
    if(shiftData && shiftData.allocated_employees == options.length){
      setSelectAllOptions(options)
    }
    else{
     setSelectAllOptions(items)
    }
  }, [options, shiftData])

  const handleEmployeeChange = employees =>{
    let employeeIds = [];
    employees.map((item)=>{
      if(item.id){
        employeeIds.push(item.id);
      }
    })
    setSelectedEmployees(employees);
    setSelectedEmployeepks(employeeIds)
  }

  const assignEmployees = () =>{
    if(selectedEmployeepks && shiftData.on_going){
      addEmployeeToRecurringShift('addEmployee', shiftId, selectedEmployeepks, shiftStartDate); 
    }
    else {
      addEmployee(shiftId, selectedEmployeepks, shiftStartDate);
    }
  }

  const renderEmployee = empId => {
    const emp = employees.length && empId && empId.id && employees.find(e => e.id == empId.id) 
    const shiftStartDate = moment().startOf('month').format('YYYY-MM-DD')
    const shiftEndDate = moment().endOf('month').format('YYYY-MM-DD')
    return (
      <Box
        direction="row"
        align="center"
        pad={editable ? 'none' : {vertical: 'small'}}
        style={!shiftManagement ? editable ? {'line-height':'35px'} : {'line-height':'15px'} : {'line-height':'15px'}}
      >
        <Box basis="small" overflow="hidden" style={{'padding-right':'10px', 'text-overflow':'ellipsis'}}>
          <Text size="small" truncate="true" style={{'text-overflow':'ellipsis'}}>{emp && emp.user && emp.user.full_name}</Text>
        </Box>
        <Box basis="small">
          <Tooltip content={emp && emp.phone_no && emp.phone_ext + ' ' +emp.phone_no} align="bottom">
            <Text size="small" truncate="true">{emp && emp.phone_no && emp.phone_ext + ' ' +emp.phone_no}</Text>
          </Tooltip>
        </Box>
        <Box basis="small" overflow="hidden" style={{'text-overflow':'ellipsis'}}>
        <Tooltip content={emp && emp.department_name && emp.team_name ? (emp.department_name + ', ' + emp.team_name) : emp.department_name && !emp.team_name ? emp.department_name : emp.team_name} align="bottom">
          <Text size="small" style={{'text-overflow':'ellipsis'}} truncate="true">{emp && emp.department_name && emp.team_name ? (emp.department_name + ', ' + emp.team_name) : emp.department_name && !emp.team_name ? emp.department_name : emp.team_name}</Text>
           </Tooltip>
        </Box>
        {/*editable && (
          <Box basis="medium" align="center">
            <CheckBox toggle />
          </Box>
        )*/}
        <Box basis="small" justify="end" align="center" direction="row">
           {<Anchor
            icon={<Image
              alt="job center"
              height="15px"
              src={process.env.PUBLIC_URL + '/img/eye-open.png'}
            />}
            style={editable ? {padding:'0px'} : {paddingRight:'15px'}}
            target="_"
            href={
              process.env.REACT_APP_METC_EXISTING_DASHBOARD_BASE_URL +
              `/timecard/custom/list/${emp && emp.id}/daily?custom=true&first_name=${emp && emp.user.first_name}&last_name=${emp && emp.user.last_name}&start_date=${shiftStartDate}&end_date=${shiftEndDate}&employee_id=${emp && emp.id}`
            }
            focusIndicator={false}
          />}
          {editable && shiftManagement &&(
            <Button
              icon={<Close size="small" />}
              onClick={() => shiftData && shiftData.on_going ? removeEmployeeFromRecurringShift('removeEmployee', emp.id) : setselectEmployee(emp.id)}  
              focusIndicator={false}
            />
          )}
        </Box>
      </Box>
    )
  }

  const initEditMode = () => {
    setSubmitShift({isEditing: true})
    closeModal()
    setShiftEditData(shift)
    setShiftDataForRecurringShift(shift)
  }

  const initDeleteMode = () => {
    setSubmitShift({isSubmitting: true})
    setLayer(shiftStartDate)
  }

  let startDate = shiftData && moment(shiftData.start_datetime).format('YYYY-MM-DD');
  let formattedShiftStartDate = shiftStartDate && moment(shiftStartDate).format('YYYY-MM-DD');
  let formattedShiftEndDate = shiftEndDate && moment(shiftEndDate).format('YYYY-MM-DD');

  let shiftstarttime = shift && shift.from && moment(shift.from).format("HH:mm")
  let shiftstartdatetime = formattedShiftStartDate + ' ' + shiftstarttime


  let diff = moment(formattedShiftStartDate).diff(startDate, 'days');//difference in days

  let timefenceStartDate = shiftData && moment(shiftData.timefence_start_time).format('YYYY-MM-DD')
  let formattedTimefenceStartDate = moment(timefenceStartDate).add(diff, 'days');//adding days in timefence start date

  let timefenceEndDate = shiftData && moment(shiftData.timefence_end_time).format('YYYY-MM-DD')
  let formattedTimefenceEndDate = moment(timefenceEndDate).add(diff, 'days');//adding days in timefence end date

  //concatinating timefence start/end time with formattedTimefenceStartDate/formattedTimefenceEndDate

  let new_timefence_start_time=shiftData && shiftData.timefence_start_time && moment(formattedTimefenceStartDate).format('YYYY-MM-DD').concat(" ",(shiftData.timefence_start_time.split(" "))[1])

  let timefenceStartTime = shiftData && shiftData.timefence_start_time && moment( new_timefence_start_time).format("HH:mm")
  let timefenceStartDateTime=
        shift && shift.reoccuring ?
        formattedTimefenceStartDate && moment(formattedTimefenceStartDate).format('YYYY-MM-DD') + ' ' + timefenceStartTime:
        formattedShiftStartDate && moment(formattedShiftStartDate).format('YYYY-MM-DD') + ' ' + timefenceStartTime;
        
  let new_timefence_end_time=shiftData && shiftData.timefence_end_time && moment(formattedTimefenceEndDate).format('YYYY-MM-DD').concat(" ",(shiftData.timefence_end_time.split(" "))[1])

   let timefenceEndTime = shiftData && shiftData.timefence_end_time && moment(new_timefence_end_time).format("HH:mm")
   let timefenceEndDateTime = 
       shift && shift.reoccuring ?                              
       formattedTimefenceEndDate && moment(formattedTimefenceEndDate).format('YYYY-MM-DD') + ' ' + timefenceEndTime :
       formattedShiftEndDate && moment(formattedShiftEndDate).format('YYYY-MM-DD') + ' ' + timefenceEndTime;

  let gracePeriod = (Math.round(( new Date(shiftStartDate).getTime() - new Date(timefenceEndDateTime).getTime()) / 60000))
 
  return (
    <Box>
        <Layer margin="large">
          <Box
            overflow="auto"
            height={{min: 'auto'}}
            background="light-1"
            width="74rem"
            round="small"
            margin={{bottom: 'large'}}
            gap="small"
          >
            <Box direction="row" justify="between" align="center" pad={{top:'small', horizontal: 'medium'}} height={{min: 'auto'}}>
              <Heading level={3} color="dark-1" size="xsmall" margin="none">
                {messages.SHIFT_MODAL_HEADING}
              </Heading>
              <Button icon={<Close size="small" />} onClick={() => closeModal('cross')} />
            </Box>
            <Divider background="accent-1" margin={{bottom: 'small'}} height="vsmall"/>
            <Box direction="row">
              <Box direction="row" gap="small" pad={{top:'small', horizontal: 'large'}} height={{min: 'auto'}} fill="horizontal">
                <Box gap="small">
                  <Heading level={6} margin="none" color="dark-1">
                    {messages.SHIFT_MODAL_TITLE_LABEL}
                  </Heading>
                  <Heading level={6} margin="none" color="dark-1">
                    {messages.SHIFT_MODAL_NUM_EMP_LABEL}
                  </Heading>
                  <Heading level={6} margin="none" color="dark-1">
                    {messages.SHIFT_MODAL_START_TIME_LABEL}
                  </Heading>
                  <Heading level={6} margin="none" color="dark-1">
                    {messages.SHIFT_MODAL_END_TIME_LABEL}
                  </Heading>
                  <Heading level={6} margin="none" color="dark-1">
                    {messages.SHIFT_MODAL_TIMEFENCE_START_TIME_LABEL}
                  </Heading>
                  <Heading level={6} margin="none" color="dark-1">
                    {messages.SHIFT_MODAL_TIMEFENCE_END_TIME_LABEL}
                  </Heading>
                  <Heading level={6} margin="none" color="dark-1">
                    {messages.SHIFT_MODAL_GRACE_PERIOD_LABEL}
                  </Heading>
                  <Heading level={6} margin="none" color="dark-1">
                    {messages.SHIFT_MODAL_REOCCURING_LABEL}
                  </Heading>
                  {/* <Heading level={6} margin="none" color="dark-1">
                    {messages.SHIFT_MODAL_P_IN_LABEL}
                  </Heading> */}
                  <Heading level={6} margin="none" color="dark-1">
                    {messages.SHIFT_MODAL_DEVIATIONS_LABEL}
                  </Heading>
                </Box>
                <Box gap="small" style={{maxWidth: '70%'}}>
                  <Text size="small" style={{overflow:'hidden', textOverflow: 'ellipsis', 'whiteSpace': 'nowrap'}}>{shift && shift.name}</Text>
                  <Text size="small">{shiftData && shiftData.allocated_employees}</Text>
                  <Text size="small">
                    {time_format_24_hr?moment(shiftStartDate).format(`${date_format}, HH:mm`):moment(shiftStartDate).format(`${date_format}, hh:mm a`)}
                  </Text>
                  <Text size="small">
                    {time_format_24_hr?moment(shiftEndDate).format(`${date_format}, HH:mm`):moment(shiftEndDate).format(`${date_format}, hh:mm a`)}
                  </Text>
                  <Text size="small">
                    { shift && shift.timefence_start_time == null ? "NA" :
                    time_format_24_hr?moment(timefenceStartDateTime).format(`${date_format}, HH:mm`):moment(timefenceStartDateTime).format(`${date_format}, hh:mm a`)}
                  </Text>
                  <Text size="small">
                    {shift && shift.timefence_end_time == null ? "NA" :
                    time_format_24_hr?moment(timefenceEndDateTime).format(`${date_format}, HH:mm`):moment(timefenceEndDateTime).format(`${date_format}, hh:mm a`)}
                  </Text>
                  <Text size="small">
                     {shift && shift.timefence_end_time == null ? "NA" : gracePeriod + ' mins'}
                  </Text>
                  {shift && shift.reoccuring && (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) ? (
                    <Box direction="row" gap="small" align="end">
                      <Text size="small">
                        {getDayLabels(shift && shift.reoccuring.days)}
                      </Text>
                      {shift.reoccuring.neverExpire ? <Text size="small">till never expire</Text> : 
                      <Text size="small">
                        {`till ${moment(shift && shift.end_date).format(date_format)}`}
                      </Text>}
                    </Box>
                  ) : ((
                    <Text size="small">{messages.SHIFT_MODAL_NA}</Text>
                  ))}
                  {/* <CheckBox toggle checked={shift && shift.punchInRestrictions} disabled /> */}
                  {shift && (shift.deviationThreshold.punch_in_before !== null || shift.deviationThreshold.punch_in_after!== null) ? (
                      <Box>
                        <Text size="small">
                          {messages.SHIFT_MODAL_NO_EARLIER_THAN +
                            ' ' +
                            shift.deviationThreshold.punch_in_before +
                            ' mins'}
                        </Text>
                        <Text size="small">
                          {messages.SHIFT_MODAL_NO_LATER_THAN +
                            ' ' +
                            shift.deviationThreshold.punch_in_after +
                            ' mins'}
                        </Text>
                      </Box>
                  ) : (
                    <Text size="small">{messages.SHIFT_MODAL_NA}</Text>
                  )}
                </Box>
              </Box>
              <Box gap="small" height={{min: 'auto'}} fill="horizontal" style={{paddingRight:'1rem', width: "85%"}}>
                {shiftManagement &&
                    <Box pad={{top: 'small'}}>
                      <Heading level={6} margin="none" color="dark-1">
                        {messages.SHIFT_MODAL_EMPLOYEES_LABEL}
                      </Heading>
                    </Box>
                  }
                  {editable && shiftData && shiftData.employee_ids && shiftManagement &&(
                    <Box direction="row" fill="horizontal">
                      <Box width="70%">
                        <MultiSelectDropDown
                          size="medium"
                          labelKey="full_name"
                          options={selectAllOptions}
                          placeholder={messages.SHIFT_MODAL_SEARCH_PLACEHOLDER}
                          dropHeight="medium"
                          replace={false}
                          onChange={handleEmployeeChange}
                          isAssignButtonLoading={isAssignButtonLoading}
                          shiftData={shiftData}
                          defaultSelectedOptions={selectedEmployees}
                        />
                      </Box>
                      <Box width="27%" style={{marginLeft:'3%'}}>
                        <Button
                          primary
                          color="#09819a"
                          label={isAssignButtonLoading ? 'Assigning...' : messages.SHIFT_MODAL_ASSIGN_BUTTON_LABEL}
                          onClick={assignEmployees}
                          disabled={!selectedEmployeepks.length || isAssignButtonLoading}
                        />
                      </Box>
                    </Box>
                  )}
                <Box
                  direction="row"
                  //margin={{top: 'medium', horizontal:'large'}}
                  pad={{vertical: 'xxsmall'}}
                  style={{paddingRight:'1.5rem', paddingLeft: '1rem'}}
                  height={{min: 'auto'}}
                >
                  <Box basis="small">
                    <Text size="small" color="dark-1">
                      {messages.SHIFT_MODAL_EMP_NAME_LABEL}
                    </Text>
                  </Box>
                  <Box basis="small">
                    <Text size="small" color="dark-1">
                      {messages.SHIFT_MODAL_EMP_MOBILE_LABEL}
                    </Text>
                  </Box>
                  <Box basis="small">
                    <Text size="small" color="dark-1">
                      {messages.SHIFT_MODAL_EMP_DEPT_LABEL}
                    </Text>
                  </Box>
                  <Box basis="small" align="end">
                    <Text size="small" color="dark-1">
                      {messages.SHIFT_MODAL_EMP_ACTIONS_LABEL}
                    </Text>
                  </Box>
                </Box>

                {shiftData && shiftData.employee_ids && shiftData.employee_ids.length && sortedEmployees && sortedEmployees.length ?
                  <Box height={{min: 'auto'}}>
                    <Box height={{max: '130px', 'min':'130px'}} overflow="auto">
                      <List
                      primaryKey={renderEmployee}
                      data={sortedEmployees && sortedEmployees}
                      pad={{vertical: 'xxsmall', horizontal: 'medium'}}
                      />
                  </Box>
                  </Box> : null}
                {shiftData && shiftData.employee_ids && !shiftData.employee_ids.length && !sortedEmployees.length ?
                  <Box height={{min: '130'}} justify="center" fill="horizontal">
                    <Text textAlign="center">No Employees Allocated</Text>
                  </Box> : null
                }
              </Box>
            </Box>
            {editable && allowShiftEdit && shiftManagement &&(
              <Box justify="end" direction="row" gap="small" pad={{top:'medium', horizontal: 'large'}} height={{min: 'auto'}}>
                <Button
                  primary
                  color="secondary"
                  label={isEditing ? 'Processing...' : messages.SHIFT_MODAL_EDIT_SHIFT_LABEL}
                  onClick={initEditMode}
                  disabled={isEditing}
                />
                <Button
                  primary
                  color="accent-2"
                  label={isSubmitting ? 'Deleting...' : messages.SHIFT_MODAL_DELETE_SHIFT_LABEL}
                  onClick={initDeleteMode}
                  disabled={isSubmitting}
                />
              </Box>
            )}
          </Box>
        </Layer>
    </Box>
  )
}

ShiftModal.defaultProps = {
  messages: {},
}

ShiftModal.propTypes = {
  data: PropTypes.object,
  messages: PropTypes.object,
}

export default ShiftModal
