import React, { useState, useEffect, useCallback } from 'react';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { withTheme } from 'styled-components';

import queryString from 'query-string';

import SectionLoaderAtom from 'atoms/SectionLoader';

import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';
import DeletePolicyModal from 'pages/paidTimeOffSettings/component/DeletePolicyModal';

import EventEmitter from 'utils/event-emitter';
import Singleton from 'utils/singleton';

import './index.css';
import CustomCard from './component/CustomCardComponent';
import Filter from './component/FilterPolicy';
import { fetchAllPtoPolicy, deletePtoPolicy } from 'pto/controllers/pto';
import { PTO_POLICY_EVENTS } from 'pto/constants';

let eventEmitter = new EventEmitter();

const StyledText = styled(Text)`
  & {
    min-width: fit-content;
  }
`;

function TimeOffSettingPage(props) {
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deleteAbleModal, setDeleteModal] = useState(false);
  const [deleteAbleData, setDeleteAbleData] = useState([]);
  const [policyData, setPolicyData] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState('true');
  const singleton = new Singleton();
  const storedStatus = localStorage.getItem('filterStatus');
  const {
    location: { search },
  } = props;

  useEffect(() => {
    const query = queryString.parse(search);
    if (singleton.oAuthToken === null) {
      singleton.oAuthToken = query.oAuthToken;
    }

    if (storedStatus) {
      setStatus(storedStatus);
    } else {
      setStatus('');
    }

    fetchTimeOffList();
  }, [search, status]);

  useEffect(function init() {
    const observable = eventEmitter.getObservable();
    let subscription;
    listenObservable();

    function listenObservable() {
      subscription = observable.subscribe(event => {
        switch (event.type) {
          case PTO_POLICY_EVENTS.SHOW_LOADER:
            setLoader(true);
            break;
          case PTO_POLICY_EVENTS.HIDE_LOADER:
            setLoader(false);
            break;
          case PTO_POLICY_EVENTS.GET_PTO_POLICY_SUCCESS:
            setPolicyData(event.data.policies);
            break;
          case PTO_POLICY_EVENTS.GET_PTO_POLICY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong, Policies are not fetched !!!',
            });
            break;
          case PTO_POLICY_EVENTS.DELETE_PTO_POLICY_SUCCESS:
            setDeleteModal(false);
            GenericWindowPostMessage('SUCCESS_TOAST', {
              toastMessage: 'Policy inactivated successfully !!!',
            });
            setPolicyData(prvData => {
              return prvData.filter(item => {
                if (item.policy_id !== event.data?.id) {
                  return item;
                }
              });
            });
            setTimeout(() => {
              setIsDeleting(false);
            }, 500);
            break;
          case PTO_POLICY_EVENTS.DELETE_PTO_POLICY_FAILURE:
            setIsDeleting(false);
            setDeleteModal(false);
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong , Unable to inactivate policy !!!',
            });
            break;
          default:
        }
      });
    }

    return () => subscription?.unsubscribe();
  }, []);

  const openDeleteModal = useCallback(items => {
    setDeleteAbleData(items);
    setDeleteModal(true);
  }, []);

  const deletePolicy = useCallback(() => {
    setIsDeleting(true);
    deletePtoPolicy(eventEmitter, {
      policy_id: deleteAbleData?.policy_id,
    });
  }, [deleteAbleData]);

  const increasePageSize = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setPageSize(pageSize + 5);
    }, 300);
  }, [pageSize]);

  const setFilterStatus = useCallback(nextValue => {
    setStatus(nextValue);
    localStorage.setItem('filterStatus', nextValue);
  }, []);

  const fetchTimeOffList = useCallback(() => {
    if (!loader) setLoader(true);
    fetchAllPtoPolicy(eventEmitter, {
      is_active: storedStatus === '' ? '' : storedStatus === 'false' ? 'false' : 'true',
      paginate: false,
    });
  }, [eventEmitter, status]);

  const handleRefresh = useCallback(
    event => {
      if (event.data === 'REFRESH_TIMEOFF_LIST') {
        fetchTimeOffList();
      }
    },
    [fetchTimeOffList],
  );
  useEffect(() => {
    window.addEventListener('message', handleRefresh);
    return () => {
      window.removeEventListener('message', handleRefresh);
    };
  }, [handleRefresh]);

  return (
    <Box pad="medium" margin={{ left: 'small', right: 'small' }} flex="grow">
      <Box pad={{ bottom: 'small' }}>
        <Filter status={status} setFilterStatus={setFilterStatus} />
      </Box>
      {loader ? (
        <SectionLoaderAtom active />
      ) : !policyData.length ? (
        <Box className="no-record-container">
          <Text className="no-record-text">No Records found, Please add a policy to see the list</Text>
        </Box>
      ) : (
        <CustomCard
          isDeleting={isDeleting}
          openDeleteModal={openDeleteModal}
          policyData={policyData}
          pageSize={pageSize}
          loader={loader}
          loading={loading}
          increasePageSize={increasePageSize}
        />
      )}

      {deleteAbleModal && (
        <DeletePolicyModal
          deletePolicy={deletePolicy}
          setDeleteModal={setDeleteModal}
          isDeleting={isDeleting}
          deleteAbleData={deleteAbleData}
        />
      )}
    </Box>
  );
}

export default withTheme(TimeOffSettingPage);
