import React, { useRef } from 'react';
import { Box, TextInput, Button, Select } from 'grommet';
import { Formik } from 'formik';
import { withTheme } from 'styled-components';
import FormField from 'granite/components/FormField';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import { createPtoPolicy, updatePtoPolicy } from 'pto/controllers/pto';

import {
  getPolicyDetailsInitialValues,
  POLICY_DETAILS_VALIDATION_SCHEMA,
  POLICY_TYPE_OPTIONS,
} from 'pages/addOrEditPtoPolicy/helper/utils';

import { PTO_TABS } from 'pto/strings';
import { PTO_POLICY_EVENTS } from 'pto/constants';

const PolicyDetailsPage = ({ policyData, eventEmitter, submitting, setSubmitting, disabled, setActiveStep, theme }) => {
  const formikRef = useRef();

  const previousPage = () => {
    GenericWindowPostMessage('PREVIOUS_PAGE');
  };

  const onSubmit = async (values, { setErrors }) => {
    if (disabled) {
      GenericWindowPostMessage(
        values?.policy_type === 'PAID_HOLIDAYS'
          ? PTO_TABS.HOLIDAY_TAB
          : values?.policy_type === 'UNPAID_TIME'
          ? PTO_TABS.EMPLOYEE_TAB
          : PTO_TABS.ACCURAL_TAB,
        {
          policyId: values?.id,
        },
      );
      setActiveStep(values?.policy_type === 'UNPAID_TIME' ? 3 : 1);
      return;
    }
    if (values?.id && !formikRef?.current?.dirty) {
      GenericWindowPostMessage(
        values?.policy_type === 'PAID_HOLIDAYS'
          ? PTO_TABS.HOLIDAY_TAB
          : values?.policy_type === 'UNPAID_TIME'
          ? PTO_TABS.EMPLOYEE_TAB
          : PTO_TABS.ACCURAL_TAB,
        {
          policyId: values?.id,
        },
      );
      setActiveStep(values?.policy_type === 'UNPAID_TIME' ? 3 : 1);
      return;
    }
    setSubmitting(true);
    setErrors({});
    values = {
      ...values,
      policy_name: values.policy_name.trim(),
    };
    !values.id ? createPtoPolicy(eventEmitter, values) : updatePtoPolicy(eventEmitter, values, values.id, 0);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getPolicyDetailsInitialValues(policyData)}
      validationSchema={POLICY_DETAILS_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box
            width="xlarge"
            pad={{
              top: 'small',
              left: 'medium',
              right: 'medium',
              bottom: 'xlarge',
            }}
          >
            <FormField name="policy_name" label="Policy Name" error={touched.policy_name && errors.policy_name}>
              <TextInput
                name="policy_name"
                id="policy_name"
                placeholder="Enter Policy Name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                value={values?.policy_name}
              />
            </FormField>
            <FormField name="policy_type" label="Policy Type" error={touched.policy_type && errors.policy_type}>
              <Select
                name="policy_type"
                options={POLICY_TYPE_OPTIONS}
                onChange={e => {
                  handleChange(e);
                  eventEmitter.emit(PTO_POLICY_EVENTS.CHANGE_TABS, e?.value);
                }}
                placeholder={'Select Policy Type'}
                value={values?.policy_type}
                labelKey="label"
                disabled={disabled}
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
            <FormField name="code" label="Policy Code">
              <TextInput
                name="code"
                id="code"
                placeholder="Enter Policy code"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                value={values?.code}
              />
            </FormField>
          </Box>
          <Box pad={{ left: 'xlarge' }} direction="row" justify="end">
            <Button
              margin={{ right: 'medium' }}
              label="Previous"
              color="accent-1"
              onClick={previousPage}
              disabled={submitting}
              primary
            />
            <Button
              type="submit"
              label={!submitting ? 'Next' : 'Submitting...'}
              color="accent-1"
              disabled={submitting}
              primary
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default withTheme(PolicyDetailsPage);
