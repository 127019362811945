import React from 'react';
import { withTheme } from 'styled-components';
import { Box, Text, TextInput, Button, Select } from 'grommet';
import { Formik, Field } from 'formik';

import FormField from 'granite/components/FormField';
import Divider from 'granite/components/Divider';

import ToolTip from 'pages/components/ToolTip';
import DateTimePickerAtom from 'generics/DateTimePickerFormik';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import { saveAccuralPolicy } from 'pto/controllers/pto';
import {
  StyledCheckBox,
  StyledSelect,
  StyledRadioButtonGroup,
  StyledAccuralDateBox,
  StyledDayWiseBox
} from 'pages/addOrEditPtoPolicy/styles';
import { ACCURAL_TYPE_OPTIONS, ACCURAL_TYPE_VALUES } from 'pto/constants';
import { ACCURAL_TAB_INFO_TEXT, ACCURAL_TAB_FORM_LABEL, PTO_TABS } from 'pto/strings';
import { ACCURAL_VALIDATION_SCHEMA, getAccuralInitialValues } from 'pages/addOrEditPtoPolicy/helper/utils';

const AccuralPolicyPage = ({
  data,
  setActiveStep,
  eventEmitter,
  dateFormat,
  setSubmitting,
  submitting,
  disabled,
  dateFormatDisplay,
}) => {
  const previousPage = () => {
    GenericWindowPostMessage(PTO_TABS.POLICY_TAB, { policyId: data.id });
    setActiveStep(0);
  };

  const onSubmit = async (values, { setErrors }) => {
    if (disabled) {
      GenericWindowPostMessage(PTO_TABS.BALANCE_TAB, {
        policyId: values?.id,
      });
      setActiveStep(2);
      return;
    }
    setErrors({});
    setSubmitting(true);
    saveAccuralPolicy(eventEmitter, values, values.id, 1);
  };

  return (
    <Formik
      initialValues={getAccuralInitialValues(data, dateFormatDisplay)}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={ACCURAL_VALIDATION_SCHEMA}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Box direction="row" pad={{ top: 'small', left: 'medium', bottom: 'medium' }}>
            <FormField label={ACCURAL_TAB_FORM_LABEL.ACCURAL_TYPE}>
              <Box direction="row" width="100%" gap="small" align="center">
                <Select
                  name="accural_type"
                  options={ACCURAL_TYPE_OPTIONS}
                  onChange={handleChange}
                  labelKey="label"
                  valueKey={{ key: 'value', reduce: true }}
                  placeholder={ACCURAL_TAB_FORM_LABEL.ACCURAL_TYPE_PLACEHOLDER}
                  value={values.accural_type}
                  disabled={disabled}
                />
                <ToolTip
                  tipMessage={`${ACCURAL_TAB_INFO_TEXT.ACCURAL_TYPE} ${values.accural_type === ACCURAL_TYPE_VALUES.EVERY_PAY_PERIOD ? ACCURAL_TAB_INFO_TEXT.ACCURAL_TYPE_EVERY_PAY_PERIOD:''}`}
                  type="Info"
                />
              </Box>
            </FormField>
          </Box>
          {values.accural_type !== ACCURAL_TYPE_VALUES.MANUAL && (
            <Box>
              <Divider background="#DCDCDC" height="2px" />
              <Box pad={{ top: 'medium', left: 'medium' }}>
                <Text size="14px" color="black">
                  Accrual Settings :
                </Text>
              </Box>
              <Box width="xlarge" pad={{ top: 'small', left: 'medium' }}>
                {values.accural_type === 'YEARLY' && (
                  <Box direction="row" gap="small" align="center">
                    <Text color="#242424" margin={{ top: 'xxsmall' }}>
                      Accrue
                    </Text>
                    <Box margin={{ left: 'xsmall', right: 'xsmall' }}>
                      <TextInput
                        style={
                          errors.total_accural_hours
                            ? {
                                width: '50px',
                                height: '22px',
                                border: '1px solid #d9534f',
                              }
                            : { width: '50px', height: '22px' }
                        }
                        name="total_accural_hours"
                        id="total_accural_hours"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.total_accural_hours}
                        disabled={disabled}
                        error={touched.total_accural_hours && errors.total_accural_hours}
                      />
                    </Box>
                    <Text color="#242424" margin={{ top: 'xxsmall' }}>
                      hours per year
                    </Text>
                    <ToolTip tipMessage={ACCURAL_TAB_INFO_TEXT.TOTAL_ACCURAL_HOURS} type="Info" />
                  </Box>
                )}
                {values.accural_type === ACCURAL_TYPE_VALUES.YEARLY &&
                touched.total_accural_hours &&
                errors.total_accural_hours ? (
                  <Text className="invalid-feedback" color="#d9534f !important">
                    {errors.total_accural_hours}
                  </Text>
                ) : null}
                {values.accural_type !== ACCURAL_TYPE_VALUES.YEARLY && (
                  <Box>
                    <Box
                      direction="row"
                      margin={{ bottom: 'xsmall' }}
                      pad={{ top: 'xsmall' }}
                      gap="small"
                      alignContent="center"
                    >
                      <Text color="#242424">Accruals will take place</Text>
                      <StyledDayWiseBox width="50px" height="24px">
                        <Select
                          width="50px"
                          height='24px'
                          name="accure_day_wise"  
                          options={[5, 4, 3, 2, 1]}
                          onChange={handleChange}
                          value={parseInt(values.accure_day_wise)}
                          disabled={disabled}
                        />
                      </StyledDayWiseBox>
                      <Text color="#242424">day's after the end of each pay period .</Text>
                    </Box>
                    {values.accural_type === ACCURAL_TYPE_VALUES.EVERY_PAY_PERIOD ? (
                      <Box direction="row" pad={{ top: 'xsmall' }} gap="small" alignContent="center" align="center">
                        <Text color="#242424">Accrue</Text>
                        <Box margin={{ left: 'xsmall', right: 'xsmall' }}>
                          <TextInput
                            style={
                              errors.total_accural_hours
                                ? {
                                    width: '50px',
                                    height: '24px',
                                    border: '1px solid #d9534f',
                                  }
                                : { width: '50px', height: '24px' }
                            }
                            name="total_accural_hours"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.total_accural_hours}
                            disabled={disabled}
                          />
                        </Box>
                        <Text color="#242424">hours </Text>
                        <StyledSelect
                          name="every_pay_period_per"
                          options={[{ value: 'year', label: 'Per Year' }]}
                          onChange={handleChange}
                          labelKey="label"
                          valueKey={{ key: 'value', reduce: true }}
                          value={values.every_pay_period_per}
                          disabled={disabled}
                        />
                        <ToolTip tipMessage={ACCURAL_TAB_INFO_TEXT.EVERY_PAY_PERIOD_PER} type="Info" />
                      </Box>
                    ) : (
                      <Box direction="row" margin={{ bottom: 'xsmall' }} gap="small" align="center">
                        <Text color="#242424" margin={{ top: 'xxsmall' }}>
                          Accrue
                        </Text>
                        <Box margin={{ left: 'xsmall', right: 'xsmall' }}>
                          <TextInput
                            style={
                              errors.total_accural_hours
                                ? {
                                    width: '50px',
                                    height: '22px',
                                    border: '1px solid #d9534f',
                                  }
                                : { width: '50px', height: '22px' }
                            }
                            name="total_accural_hours"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.total_accural_hours}
                            disabled={disabled}
                          />
                        </Box>
                        <Text color="#242424" margin={{ top: 'xxsmall' }}>
                          hours per
                        </Text>
                        <Box margin={{ left: 'xsmall', right: 'xsmall' }}>
                          <TextInput
                            style={
                              errors.based_on_hours_worked_per
                                ? {
                                    width: '50px',
                                    height: '22px',
                                    border: '1px solid #d9534f',
                                  }
                                : { width: '50px', height: '22px' }
                            }
                            name="based_on_hours_worked_per"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.based_on_hours_worked_per}
                            disabled={disabled}
                          />
                        </Box>
                        <Text color="#242424" margin={{ top: 'xxsmall' }}>
                          hours worked
                        </Text>
                        <ToolTip tipMessage={ACCURAL_TAB_INFO_TEXT.BASED_ON_HOURS_WORKED_PER} type="Info" />
                      </Box>
                    )}
                  </Box>
                )}
                {values.accural_type !== ACCURAL_TYPE_VALUES.YEARLY &&
                touched.total_accural_hours &&
                errors.total_accural_hours ? (
                  <Text className="invalid-feedback" color="#d9534f !important">
                    {errors.total_accural_hours}
                  </Text>
                ) : null}
                {values.accural_type === ACCURAL_TYPE_VALUES.BASED_ON_HOURS_WORKED &&
                touched.based_on_hours_worked_per &&
                errors.based_on_hours_worked_per ? (
                  <Text className="invalid-feedback" color="#d9534f !important">
                    {errors.based_on_hours_worked_per}
                  </Text>
                ) : null}
                <Box direction="row" pad={{ top: 'small', bottom: 'medium' }} gap="small" align="center">
                  <StyledCheckBox
                    name="use_it_or_lose_it"
                    onChange={handleChange}
                    checked={values.use_it_or_lose_it}
                    disabled={disabled}
                  />
                  <Text color="#242424" pad={{ left: 'xsmall' }} margin={{ top: 'xsmall' }}>
                    {values.accural_type === ACCURAL_TYPE_VALUES.BASED_ON_HOURS_WORKED
                      ? 'Use it or lose it ( Reset balance on Jan 1st )'
                      : 'Use it or lose it '}
                  </Text>
                  <ToolTip tipMessage={ACCURAL_TAB_INFO_TEXT.USE_IT_OR_USE_IT} type="Info" />
                </Box>
              </Box>
              <Box>
                <Divider background="#DCDCDC" height="2px" />
                <Box pad={{ top: 'medium', left: 'medium' }}>
                  <Text size="14px" color="black">
                    Accrual Date Settings :
                  </Text>
                </Box>
                <Box pad={{ top: 'medium', left: 'medium' }}>
                  {values.accural_type !== ACCURAL_TYPE_VALUES.EVERY_PAY_PERIOD ? (
                    <Box direction="row" gap="small" align="center">
                      <Text color="black">Accruals will happen on :</Text>
                      <ToolTip tipMessage={ACCURAL_TAB_INFO_TEXT.ACCURAL_HAPPENS} type="Info" />
                    </Box>
                  ) : (
                    <Text color="#242424">Reset yearly balance on (only if use it or lose it is checked) :</Text>
                  )}
                  <Box>
                    <StyledRadioButtonGroup
                      name="accural_on_hire_date"
                      id="accural_on_hire_date"
                      disabled={disabled}
                      options={[
                        { label: 'Hire Date', value: 'true' },
                        {
                          label: (
                            <StyledAccuralDateBox
                              margin="xxsmall"
                              disabled={(values.accural_on_hire_date === 'true' ? true : false) || disabled}
                            >
                              <Field
                                id="accural_date"
                                name="accural_date"
                                placeholder="Select Date"
                                component={DateTimePickerAtom}
                                options={{ dateFormat }}
                                disabled={(values.accural_on_hire_date === 'true' ? true : false) || disabled}
                              />
                            </StyledAccuralDateBox>
                          ),
                          value: 'false',
                        },
                      ]}
                      value={values.accural_on_hire_date}
                      onChange={handleChange}
                      //disabled={values.accural_on_hire_date === 'true' ? true : false}
                    />
                  </Box>
                  {values.accural_type === ACCURAL_TYPE_VALUES.YEARLY && (
                    <Box direction="row" pad={{ top: 'medium', bottom: 'medium' }} gap="small" align="center">
                      <StyledCheckBox
                        name="allow_entries_outside_employee_current_accural_year"
                        checked={values.allow_entries_outside_employee_current_accural_year}
                        onChange={handleChange}
                        disabled={disabled}
                      />
                      <Text color="#242424" margin={{ left: 'xsmall', top: 'xsmall' }}>
                        {ACCURAL_TAB_FORM_LABEL.ALLOW_ENTRIES_OUTSIDE_EMPLOYEE_CURRENT_ACCURAL_YEAR}
                      </Text>
                      <ToolTip
                        tipMessage={ACCURAL_TAB_INFO_TEXT.ALLOW_ENTRIES_OUTSIDE_EMPLOYEE_CURRENT_ACCURAL_YEAR}
                        type="Info"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          <Box pad={{ left: 'xlarge' }} direction="row" justify="end">
            <Button
              margin={{ right: 'medium' }}
              label="Previous"
              color="accent-1"
              onClick={previousPage}
              disabled={submitting}
              primary
            />
            <Button
              type="submit"
              label={submitting ? 'Submitting...' : 'Next'}
              color="accent-1"
              disabled={submitting}
              primary
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default withTheme(AccuralPolicyPage);
