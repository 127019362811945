import { CheckBox, RadioButtonGroup, Select, Box, TextInput } from 'grommet';
import styled from 'styled-components';

export const StyledCheckBox = styled(CheckBox)`
  & + div {
    height: 15px;
    width: 15px;
  }
`;

export const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  & > label > div {
    height: 15px;
    width: 15px;
    font-size: 14 px;
  }

  ,
  & > label {
    font-size: 14px;
  }
`;
export const StyledSelect = styled(Select)`
  & > label > div > div > svg {
    height: 15px;
    width: 15px;
    border-box: 2px solid red;
  }
  width: 70px;
  height: 20px;
`;

export const StyledAccuralDateBox = styled(Box)`
  background: white;
  width: 180px !important;
  height: 33px !important;
  border: 0px solid ridge;
`;

export const StyledTextInput = styled(TextInput)`
  opacity: 1;
  border: 1px solid red;
`;

export const StyledProgressBarImage = styled.img`
  height: 20px;
  width: 25px;
  margin-top: -6px;
  margin-right: 8px;
  vertical-align: middle;
`;

export const StyledBalanceSettingInput = styled(TextInput)`
  & {
    height: 30px;
    border-radius: 4px 0px 0px 4px;
  }
`;

export const StyledDays = styled(Box)`
  background: #eee;
  border: 1px solid #cbcbcb;
  border-radius: 0px 4px 4px 0px;
  opacity: ${({ disabled, waitingPeriod }) => (disabled ? 0.3 : waitingPeriod ? null : 0.3)};
`;

export const StyledHours = styled(Box)`
  background: #eee;
  border: 1px solid #cbcbcb;
  border-radius: 0px 4px 4px 0px;
  opacity: ${({ disabled, carryOverLimit }) => (disabled ? 0.3 : carryOverLimit ? null : 0.3)};
`;

export const StyledAllEmployeeCheckBox = styled(CheckBox)`
  & + div {
    height: 15px;
    width: 15px;
  }
`;

export const StyledDayWiseBox = styled(Box)`
  min-width: 50px;
  input{
    padding:0 5px;
    width:inherit;
  }
  button {
    & > div > *:last-child {
      margin: 0;
    }
  }
`;

export const EmployeeTableStyles = { background: '#FFF', maxHeight: '750px' };
