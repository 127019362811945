// Lib Imports
// TODO: remove moment and moment-range(if possible)
import React, {useEffect, useRef, useState, useCallback} from 'react'
import {Box, Grid, Button, Image} from 'grommet'
import {Draggable} from '@fullcalendar/interaction'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import _ from 'lodash'

// CORE imports
import FullCalendar from 'pages/components/FullCalendar'
import Spinner from 'granite/components/Spinner'
import Toast from 'granite/components/Toast'
import Divider from 'granite/components/Divider'
import EventEmitter from 'utils/event-emitter'
import DateTimePicker from 'granite/components/DateTimePicker'
import {DatePickerComponent} from '@syncfusion/ej2-react-calendars'
import Cookies from 'universal-cookie'
import queryString from 'query-string'
import Singleton from 'utils/singleton'
import { setAuthToken } from 'utils/auth-singleton';

// Application Imports
import Filters from './components/Filters'
import ShiftAddEditModal from 'pages/components/ShiftAddEditModal'
import ShiftDeleteModal from 'pages/components/ShiftDeleteModal'
import RecurringShiftEditModal from 'pages/components/RecurringShiftEditModal'
import HeaderComponent from 'pages/components/headerComponent'
import ShiftModal from 'pages/components/ShiftModal'
import EmployeeRemoveModal from 'pages/components/EmployeeRemoveModal'
import ShiftMergeModal from 'pages/components/ShiftMergeModal'
import companyAPIGateway from 'company/gateways/company-api'
import messages from 'messages/shiftManagement'
import {mapEventToShift} from 'pages/components/FullCalendar/utils'
import './index.css'
import {fetchDepartments} from 'department/controllers/department'
import {fetchTeams} from 'team/controllers/team'
import {fetchCompanies, getBasicSettings} from 'company/controllers/company'
import {fetchEmployees, fetchEmployeesData} from 'employee/controllers/employee'
import {
  createShifts,
  fetchAllShifts,
  fetchShiftDetails,
  updateShifts,
  deleteShifts,
  mapEmployeeToShift,
  updateEmployeeShiftMap,
} from 'shifts/controllers/shift'
import {
  DEPARTMENT_EVENTS,
  TEAM_EVENTS,
  COMPANY_EVENTS,
  EMPLOYEE_EVENTS,
  SHIFT_EVENTS,
} from './constants'

import * as UserDucks from 'accounts/ducks/user'

const debounceFetchAllShifts = _.debounce(fetchAllShifts, 100, {
  trailing: true,
  leading: false,
})
const defaultShiftLength = 8
const defaultShiftModalData = {shiftId: null}
const defaultClickedDateData = {from: null, to: null}
const defaultOverlappingShiftsData = {shift: null, overlappingShifts: []}
const defaultToastData = {visible: false, text: '', background: 'accent-1'}
const cookies = new Cookies()
let startDate = ''
let endDate = ''
let date_format = ''
let time_format_24_hr = false
let employeeOfShift = ''
let shiftStartDate = ''
let shiftEndDate = ''

let eventEmitter = new EventEmitter()

function ShiftManagementPage(props) {
  const {userProfile} = props
  const [shifts, setShifts] = useState([])
  const [shiftData, setShiftData] = useState([])
  const [employeesData, setEmployeesData] = useState([])
  const [shiftModalData, setShiftModalData] = useState(defaultShiftModalData)
  const [clickedDateData, setClickedDateData] = useState(defaultClickedDateData)
  const [overlappingShiftsData, setOverlappingShiftsData] = useState(
    defaultOverlappingShiftsData,
  )
  const [toastData, setToastData] = useState(defaultToastData)
  const externalEventsContainerRef = useRef()
  const [departments, setDepartments] = useState([])
  const [defaultTeams, setTeams] = useState([])
  const [companies, setCompanies] = useState([])
  const [layer, setLayer] = useState()
  const [selectEmployee, setselectEmployee] = useState()
  const [defaultEmployees, setEmployees] = useState([])
  const [loader, setLoader] = useState({
    isShiftLoading: true,
  })
  const [view, setView] = useState('')
  const [RecurringShiftModalOpen, setRecurringShiftModalOpen] = useState(false)
  const [RecurringShift, setRecurringShift] = useState([])
  const [shiftDataForRecurringShift, setShiftDataForRecurringShift] = useState(
    [],
  )
  const [oldShiftData, setOldShiftData] = useState([])
  const [revertFunc, setRevert] = useState(false)
  const [shiftDetailsEdit, setShiftDetailsEdit] = useState(false)
  const [recurringEmployeeId, setRecurringEmployeeId] = useState()
  const [recurringShiftId, setRecurringShiftId] = useState()
  const [recurringOptionId, setRecurringOptionId] = useState()
  const [recurringShiftDate, setRecurringShiftDate] = useState()
  const [disabledEventClick, setDisabledEventClick] = useState(false)
  const [isAssignButtonLoading, setAssignButtonLoading] = useState(false)
  const [selectedEmployees ,setSelectedEmployees]=useState([])
  const [submitShift, setSubmitShift] = useState({
    isSubmitting: false,
    isEditing: false,
    isDeleting: false,
    isSavingOnly: false,
    isSavingUpcoming: false,
  })
  const [initialViewDate, setInitialViewDate] = useState(new Date())
  const singleton = new Singleton()
  const {
    location: {match, search},
  } = props

  useEffect(() => {
    const query = queryString.parse(search)
    setAuthToken(query.oAuthToken)
    fetchCompanies(eventEmitter, {
      paginate: false,
      company_id: +query.company_id,
    })
  }, [search])

  useEffect(function init() {
    const observable = eventEmitter.getObservable()
    let subscription
    listenObservable()

    function listenObservable() {
      subscription = observable.subscribe(event => {
        switch (event.type) {
          case DEPARTMENT_EVENTS.GET_DEPARTMENT_SUCCESS:
            setDepartments(event.data.departments)
            break
          case DEPARTMENT_EVENTS.GET_DEPARTMENT_FAILURE:
            setToastData({
              visible: true,
              text:
                event.data ||
                'something went wrong, departments are not fetched',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case TEAM_EVENTS.GET_TEAM_SUCCESS:
            setTeams(event.data.teams)
            break
          case TEAM_EVENTS.GET_TEAM_FAILURE:
            setToastData({
              visible: true,
              text: event.data || 'something went wrong, teams are not fetched',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case COMPANY_EVENTS.GET_COMPANY_SUCCESS:
            setCompanies(event.data.companies)
            break
          case COMPANY_EVENTS.GET_COMPANY_FAILURE:
            setToastData({
              visible: true,
              text:
                event.data || 'something went wrong, companies are not fetched',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case EMPLOYEE_EVENTS.GET_EMPLOYEE_SUCCESS:
            setEmployees(event.data.employees)
            break
          case EMPLOYEE_EVENTS.GET_EMPLOYEE_FAILURE:
            setToastData({
              visible: true,
              text:
                event.data || 'something went wrong, emloyees are not fetched',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case EMPLOYEE_EVENTS.GET_EMPLOYEE_DATA_SUCCESS:
            setEmployeesData(event.data)
            break
          case EMPLOYEE_EVENTS.GET_EMPLOYEE_DATA_FAILURE:
            setToastData({
              visible: true,
              text:
                event.data || 'something went wrong, emloyees are not fetched',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case SHIFT_EVENTS.GET_ALL_SHIFTS_SUCCESS:
            setShifts(event.data.shifts)
            setRecurringShiftModalOpen(false)
            setSubmitShift({isSavingOnly: false, isSavingUpcoming: false})
            break
          case SHIFT_EVENTS.GET_ALL_SHIFTS_FAILURE:
            setToastData({
              visible: true,
              text:
                event.data ||
                'something went wrong, all shifts are not fetched',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case SHIFT_EVENTS.DELETE_SHIFT_SUCCESS:
            setSubmitShift({
              isSubmitting: false,
              isDeleting: false,
              isSavingOnly: false,
              isSavingUpcoming: false,
            })
            setLayer(false)
            setShiftModalData({shiftId: null})
            setRecurringShiftModalOpen(false)
            setShiftStartEndDateNull()
            setToastData({
              visible: true,
              text: 'Shift Deleted Successfully',
              background: 'accent-1',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case SHIFT_EVENTS.DELETE_SHIFT_FAILURE:
            setSubmitShift({
              isSubmitting: false,
              isDeleting: false,
              isSavingOnly: false,
              isSavingUpcoming: false,
            })
            setLayer(false)
            setShiftModalData({shiftId: null})
            setRecurringShiftModalOpen(false)
            setShiftStartEndDateNull()
            setToastData({
              visible: true,
              text: event.data || 'Something went wrong.',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case SHIFT_EVENTS.CREATE_SHIFT_SUCCESS:
            setLoader({isShiftLoading: false})
            setSubmitShift({isSubmitting: false})
            setDisabledEventClick(false)
            setToastData({
              visible: true,
              text: 'Shift Created Successfully',
              background: 'accent-1',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            setClickedDateData({from: null, to: null})
            break
          case SHIFT_EVENTS.CREATE_SHIFT_FAILURE:
            setLoader({isShiftLoading: false})
            setSubmitShift({isSubmitting: false})
            setDisabledEventClick(false)
            setToastData({
              visible: true,
              text:
                event.data ||
                'something went wrong, Required field cannot be blank',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case SHIFT_EVENTS.GET_SHIFT_DETAILS_SUCCESS:
            setShiftModalData({shiftId: event.data.id})
            setShiftData(event.data)
            break
          case SHIFT_EVENTS.GET_SHIFT_DETAILS_FAILURE:
            shiftStartDate = null
            shiftEndDate = null
            setToastData({
              visible: true,
              text:
                event.data || 'something went wrong, shift data is not fetched',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case SHIFT_EVENTS.UPDATE_SHIFT_SUCCESS:
            setSubmitShift({isSubmitting: false, isEditing: false})
            setRecurringShiftModalOpen(false)
            setShiftStartEndDateNull()
            setToastData({
              visible: true,
              text: 'Shift Successfully Updated',
              background: 'accent-1',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            setClickedDateData({from: null, to: null})
            break
          case SHIFT_EVENTS.UPDATE_SHIFT_FAILURE:
            setSubmitShift({isSubmitting: false, isEditing: false})
            setRecurringShiftModalOpen(false)
            setShiftStartEndDateNull()
            setToastData({
              visible: true,
              text:
                event.data ||
                'something went wrong,Required field cannot be blank',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case SHIFT_EVENTS.SHOW_LOADER:
            setLoader({isShiftLoading: true})
            break
          case SHIFT_EVENTS.HIDE_LOADER:
            if (event.data === true) setLoader({isShiftLoading: false})
            break
          case SHIFT_EVENTS.ADD_EMPLOYEE_TO_SHIFT_SUCCESS:
            // setSubmitShift({isSavingOnly: false, isSavingUpcoming: false})
            // setRecurringShiftModalOpen(false)
            setOverlappingShiftsData({shift: null, overlappingShifts: []})
            setDisabledEventClick(false)
            setAssignButtonLoading(false)
            setToastData({
              visible: true,
              text: 'Employee Added Successfully',
              background: 'accent-1',
            })
            setTimeout(
              () =>
                setToastData({
                  visible: false,
                  text: '',
                  background: 'accent-1',
                }),
              3000,
            )
            break
          case SHIFT_EVENTS.ADD_EMPLOYEE_TO_SHIFT_FAILURE:
            setSubmitShift({isSavingOnly: false, isSavingUpcoming: false})
            setRecurringShiftModalOpen(false)
            setAssignButtonLoading(false)
            setToastData({
              visible: true,
              text: event.data || 'Employee assignment failed',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case SHIFT_EVENTS.UPDATE_EMPLOYEE_SHIFT_MAP_SUCCESS:
            // setSubmitShift({isDeleting: false, isSavingUpcoming: false, isSavingOnly: false})
            if (event.data.updated_shift_id)
              setShiftModalData({shiftId: event.data.updated_shift_id})
            setselectEmployee(false)
            // setRecurringShiftModalOpen(false)
            setToastData({
              visible: true,
              text: 'Employee Removed Successfully',
              background: 'accent-1',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
              setLoader({isShiftLoading: false})
            }, 3000)
            break
          case SHIFT_EVENTS.UPDATE_EMPLOYEE_SHIFT_MAP_FAILURE:
            setSubmitShift({
              isDeleting: false,
              isSavingUpcoming: false,
              isSavingOnly: false,
            })
            setRecurringShiftModalOpen(false)
            setToastData({
              visible: true,
              text: event.data || 'Employee assignment failed',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          case COMPANY_EVENTS.GET_BASIC_SETTING_SUCCESS:
            date_format = event.data.dateFormatDisplay
            time_format_24_hr = event.data.timeFormat24Hrs
            break
          case COMPANY_EVENTS.GET_BASIC_SETTING_FAILURE:
            setToastData({
              visible: true,
              text: event.err || 'something went wrong',
              background: 'accent-2',
            })
            setTimeout(() => {
              setToastData({visible: false, text: '', background: 'accent-1'})
            }, 3000)
            break
          default:
        }
      })
    }
  }, [])

  const fetchData = async () => {
    const query = queryString.parse(search);
    const subCompaniesData = await companyAPIGateway.fetchCompanies({ paginate: false,company_id: +query.company_id, });
    setCompanies(subCompaniesData.companies);
    getBasicSettings(eventEmitter, {
      company_id: +query.company_id,
    });
    const subCompaniesId = subCompaniesData?.companies.map(item => item.id);
    const companiesId = JSON.stringify(subCompaniesId)
    fetchDepartments(eventEmitter, {
      paginate: false,
      get_active: true,
      get_active_teams: true,
      is_active: true,
      company_id: companiesId,
    })

    fetchTeams(eventEmitter, {
      paginate: false,
      company_id: companiesId,
      is_active: true,
    })

    fetchEmployees(eventEmitter, {
      company_id: companiesId,
      is_active: true,
      paginate: false,
      ordering: 'user__first_name_case_insensitive'
    })
  }

  useEffect(()=>{
      const query = queryString.parse(search)
      if (singleton.oAuthToken === null) {
        singleton.oAuthToken = query.oAuthToken
      }
      fetchData()
    },
    [search,singleton],
  )

  const handlePageRefresh = useCallback(
    event => {
    if (event.data === 'REFRESH_SHIFT_MANAGEMENT') {
        const { pathname, search } = window.location;
        window.location.href = `${pathname}${search}`;
      }
    },
    []
  );

  useEffect(() => {
    window.addEventListener('message', handlePageRefresh);
    return () => {
      window.removeEventListener('message', handlePageRefresh);
    };
  }, [handlePageRefresh]);

  //useEffect calls handleshRefresh button to fetch all shifts after every 1 minute
  useEffect(() => {
    const timer = setInterval(() => handleRefresh('doNotShowloader'), 60000)
    return () => clearInterval(timer)
  })

  //to save the last scrolled position of the calendar view
  var calendarPosition = document.getElementsByClassName('fc-view-container')[0]
  if (calendarPosition) {
    document
      .getElementsByClassName('fc-view-container')[0]
      .addEventListener('scroll', event => {
        var container = document.getElementsByClassName('fc-view-container')[0]
        cookies.set('position', container.scrollTop)
      })
  }

  const onDepartmentSelect = data => {
    const deptIds =
      data.departments && data.departments.length
        ? data.departments.map(i => i.id)
        : []
    const teamIds =
      data.selectedTeams && data.selectedTeams.length
        ? data.selectedTeams.map(i => i.id)
        : []
    const subCompaniesId = companies.map(item => item.id)
    const companiesId = JSON.stringify(subCompaniesId)
    fetchTeams(eventEmitter, {
      paginate: false,
      company_id: companiesId,
      is_active: true,
      department_ids: JSON.stringify(deptIds),
    })

    fetchEmployees(eventEmitter, {
      company_id: companiesId,
      is_active: true,
      paginate: false,
      department_ids: JSON.stringify(deptIds),
      team_ids: JSON.stringify(teamIds),
    })
  }

  const onTeamSelect = data => {
    const teamIds =
      data.teams && data.teams.length ? data.teams.map(i => i.id) : []
    const departmentIds =
      data.selectedDepartments && data.selectedDepartments.length
        ? data.selectedDepartments.map(item => item.id)
        : []
    const subCompaniesId = companies.map(item => item.id)
    const companiesId = JSON.stringify(subCompaniesId)
    fetchEmployees(eventEmitter, {
      company_id: companiesId,
      is_active: true,
      paginate: false,
      team_ids: JSON.stringify(teamIds),
      department_ids: JSON.stringify(departmentIds),
    })
  }

  // const func1 = useCallback(data => {
  //   setShifts(data)
  // }, [])

  const createShift = (shiftData, dragAddEmployee) => {
    let {from, to} = clickedDateData
    if (!from || !to) {
      from = shiftData.from
      to = shiftData.to
    }
    const shift_duration = (to - from) / (1000 * 60 * 60)
    const newShift = {
      ...shiftData,
      start_datetime: moment
        .utc(shiftData.startDateTime)
        .format('YYYY-MM-DD HH:mm'),
      end_datetime: moment
        .utc(shiftData.endDateTime)
        .format('YYYY-MM-DD HH:mm'),
      punchedInIds: [],
      shift_duration: shift_duration,
      company_id: userProfile.company_id,
      startDate: moment(startDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD'),
      endDate: moment(endDate)
        .add(1, 'months')
        .format('YYYY-MM-DD'),
    }
    createShifts(
      eventEmitter,
      {
        ...newShift,
      },
      dragAddEmployee,
    )

  }

  const saveShift = ({newName, expectedEmployees}, dragAddEmployee) => {
    const {shift} = overlappingShiftsData
    shift.name = newName
    shift.no_of_employees = expectedEmployees
    shift.timefence_end_time = new Date(shift.from)
    shift.timefence_start_time = moment(shift.from)
      .subtract(30, 'minutes')
      .toDate()
    const {id, ...rest} = shift
    createShift(rest, dragAddEmployee)
  }

  const deleteShift = permanentType => {
    const {shiftId} = shiftModalData
    if (permanentType) {
      setSubmitShift({isSavingUpcoming: true})
      deleteShifts(eventEmitter, {
        delete_date: moment.utc(layer).format('YYYY-MM-DD'),
        startDate: moment(startDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD'),
        endDate: moment(endDate)
          .add(1, 'months')
          .format('YYYY-MM-DD'),
        shiftId: shiftId,
        permanent_change: true,
      })
    } else {
      setSubmitShift({isSavingOnly: true, isDeleting: true, isSubmitting: true})
      deleteShifts(eventEmitter, {
        delete_date: moment.utc(layer).format('YYYY-MM-DD'),
        startDate: moment(startDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD'),
        endDate: moment(endDate)
          .add(1, 'months')
          .format('YYYY-MM-DD'),
        shiftId: shiftId,
      })
    }
  }

  const addEmployeeToShift = (
    shiftId,
    employeeId,
    shiftData,
    permanentType,
    dragAddEmployee,
  ) => {
    const shift = shifts.find(shft => shft.id == shiftId)
    let employeeAlreadyExists
    if (shift) {
      const existingEmployeeIds = shift.employeeIds
      employeeAlreadyExists = existingEmployeeIds.find(
        empId => employeeId == empId,
      )
    }
    const isPastEvent = moment(Date.now()).isAfter(shiftData || shift.from)
    if (isPastEvent) {
      setToastData({
        visible: true,
        text: messages.CURRENT_SHIFT_ADD_EMPLOYEE_TO_SHIFT,
        background: 'accent-2',
      })
      setTimeout(
        () => setToastData({visible: false, text: '', background: 'accent-1'}),
        3000,
      )
      return
    }
    if (employeeAlreadyExists) {
      setToastData({
        visible: true,
        text: 'Employee Already Exists',
        background: 'accent-2',
      })
      setSubmitShift({isSavingOnly: false, isSavingUpcoming: false})
      setTimeout(
        () => setToastData({visible: false, text: '', background: 'accent-1'}),
        3000,
      )
    } else {
      setAssignButtonLoading(true)
      shift.employeeIds.push(employeeId)
      if (permanentType) {
        mapEmployeeToShift(
          eventEmitter,
          {
            shift_id: shiftId,
            employee_ids: dragAddEmployee ? [employeeId] : employeeId,
            permanent_change: true,
            add_date: moment.utc(shiftData).format('YYYY-MM-DD'),
          },
          {
            start_date: moment(startDate)
              .subtract(1, 'months')
              .format('YYYY-MM-DD'),
            end_date: moment(endDate)
              .add(1, 'months')
              .format('YYYY-MM-DD'),
          },
          dragAddEmployee,
        )
      } else {
        mapEmployeeToShift(
          eventEmitter,
          {
            shift_id: shiftId,
            employee_ids: dragAddEmployee ? [employeeId] : employeeId,
            add_date: moment.utc(shiftData).format('YYYY-MM-DD'),
          },
          {
            start_date: moment(startDate)
              .subtract(1, 'months')
              .format('YYYY-MM-DD'),
            end_date: moment(endDate)
              .add(1, 'months')
              .format('YYYY-MM-DD'),
          },
          dragAddEmployee,
        )
      }
    }
    setSelectedEmployees([])
  }

  const updateShift = shift => {
    let {from, to} = clickedDateData
    if (!from || !to) {
      from = shiftDataForRecurringShift.from
      to = shiftDataForRecurringShift.to
    }

    const shift_duration = (to - from) / (1000 * 60 * 60)
    const newShift = {
      ...shift,
      start_datetime: moment
        .utc(shift.startDateTime)
        .format('YYYY-MM-DD HH:mm'),
      end_datetime: moment.utc(shift.endDateTime).format('YYYY-MM-DD HH:mm'),
      employeeIds: [],
      punchedInIds: [],
      shift_duration: shift_duration,
      company_id: userProfile.company_id,
      edit_date: moment.utc(shiftStartDate).format('YYYY-MM-DD'),
      startDate: moment(startDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD'),
      endDate: moment(endDate)
        .add(1, 'months')
        .format('YYYY-MM-DD'),
      from_edit_date: moment.utc(shiftStartDate).format('YYYY-MM-DD'),
    }
    updateShifts(
      eventEmitter,
      {
        ...newShift,
      },
      'edit',
    )
  }

  const getEmployeeShifts = (empId, boolean) => {
    let newStartDate = viewCookie.includes('Month')
      ? moment(startDate)
          .subtract(1, 'week')
          .format('YYYY-MM-DD')
      : moment(startDate).format('YYYY-MM-DD')
    let newEndDate = viewCookie.includes('Month')
      ? moment(endDate)
          .add(2, 'week')
          .format('YYYY-MM-DD')
      : moment(endDate).format('YYYY-MM-DD')

    if (boolean) {
      employeeOfShift = empId
      fetchAllShifts(eventEmitter, {
        paginate: false,
        employee_id: empId,
        start_date: newStartDate,
        end_date: newEndDate,
      })
    } else {
      employeeOfShift = ''
      fetchAllShifts(eventEmitter, {
        paginate: false,
        start_date: newStartDate,
        end_date: newEndDate,
      })
    }
  }

  const updateDragShift = shift => {
    const {from, to} = shift

    const shift_duration = (to - from) / (1000 * 60 * 60)
    const newShift = {
      ...shift,
      start_datetime: moment.utc(from).format('YYYY-MM-DD HH:mm'),
      end_datetime: moment.utc(to).format('YYYY-MM-DD HH:mm'),
      timefence_end_time: moment
        .utc(shift.timefence_end_time)
        .format('YYYY-MM-DD HH:mm'),
      timefence_start_time: moment
        .utc(shift.timefence_start_time)
        .format('YYYY-MM-DD HH:mm'),
      employeeIds: [],
      punchedInIds: [],
      shift_duration: shift_duration,
      company_id: userProfile.company_id,
      edit_date: shift.from_edit_date || moment.utc(from).format('YYYY-MM-DD'),
      startDate: moment(startDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD'),
      endDate: moment(endDate)
        .add(1, 'months')
        .format('YYYY-MM-DD'),
      from_edit_date:
        shift.from_edit_date || moment.utc(from).format('YYYY-MM-DD'),
    }
    updateShifts(eventEmitter, {
      ...newShift,
    })
  }

  const removeEmployeeFromShift = (employeeId, permanentType) => {
    const {shiftId} = shiftModalData
    if (permanentType) {
      setSubmitShift({isSavingUpcoming: true})
      updateEmployeeShiftMap(
        eventEmitter,
        {
          shift_id: shiftId,
          employee_id: employeeId,
          is_deleted: true,
          permanent_change: true,
          edit_date: moment.utc(shiftStartDate).format('YYYY-MM-DD'),
        },
        {
          start_date: moment(startDate)
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          end_date: moment(endDate)
            .add(1, 'months')
            .format('YYYY-MM-DD'),
        },
      )
    } else {
      setSubmitShift({isSavingOnly: true, isDeleting: true})
      updateEmployeeShiftMap(
        eventEmitter,
        {
          shift_id: shiftId,
          employee_id: employeeId,
          is_deleted: true,
          edit_date: moment.utc(shiftStartDate).format('YYYY-MM-DD'),
        },
        {
          start_date: moment(startDate)
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          end_date: moment(endDate)
            .add(1, 'months')
            .format('YYYY-MM-DD'),
        },
      )
    }
  }

  /**
   * adding dragable properties to external events through javascript
   */
  const initDragable = () => {
    if (!externalEventsContainerRef.current) return
    let draggableEl = externalEventsContainerRef.current
    new Draggable(draggableEl, {
      itemSelector: '.dragable-event',
      eventData: function(eventEl) {
        let empName = eventEl.getAttribute('name')
        let empId = eventEl.getAttribute('data')
        return {
          title: empName + ' -Shift',
          id: empId + Date.now(),
          employees: [{name: empName, id: empId}],
        }
      },
    })
  }
  useEffect(initDragable, [externalEventsContainerRef])

  /**
   * when we click on event we are displaying event details
   */
  const eventClick = eventClick => {
    if (Boolean(eventClick.event.title) === false) return
    const fullcalendarEvent = eventClick.event
    shiftStartDate = fullcalendarEvent.start
    shiftEndDate = fullcalendarEvent.end
    if (!disabledEventClick) {
      fetchShiftDetails(eventEmitter, {
        shift_id: fullcalendarEvent.id,
      })
    }
  }

  const eventReceive = info => {
    // called after external drop events
    const createdFullcalendarEvent = info.event
    createdFullcalendarEvent.remove()

    // NOTE: Prevent event drop on past
    const isPastEvent = moment(Date.now()).isAfter(
      createdFullcalendarEvent.start,
    )
    if (isPastEvent) {
      setToastData({
        visible: true,
        text: messages.PAST_DATE_ADD_EMPLOYEE_TO_SHIFT,
        background: 'accent-2',
      })
      setTimeout(
        () => setToastData({visible: false, text: '', background: 'accent-1'}),
        3000,
      )
      return
    }

    const shiftObject = mapEventToShift(createdFullcalendarEvent)
    shiftObject.to = moment(shiftObject.from)
      .add(defaultShiftLength, 'hours')
      .toDate()
    shiftStartDate = new Date(shiftObject.from)
    shiftEndDate = new Date(shiftObject.to)
    setDisabledEventClick(true)
    const overlappingShifts = getPossibleOverlappingShifts({
      ...shiftObject,
      startDateTime: new Date(shiftObject.from),
      endDateTime: new Date(shiftObject.to),
    })
    if (overlappingShifts && overlappingShifts.length > 0) {
      setOverlappingShiftsData({
        shift: {
          ...shiftObject,
          startDateTime: new Date(shiftObject.from),
          endDateTime: new Date(shiftObject.to),
        },
        overlappingShifts,
      })
      setLoader({isShiftLoading: false})
    } else {
      createShift(
        {
          ...shiftObject,
          startDateTime: new Date(shiftObject.from),
          endDateTime: new Date(shiftObject.to),
          timefence_end_time: new Date(shiftObject.from),
          timefence_start_time: moment(shiftObject.from)
            .subtract(30, 'minutes')
            .toDate(),
        },
        'dragAddEmployee',
      )
      if (shifts && shifts.length) {
        setShifts([
          ...shifts,
          {
            ...shiftObject,
            startDateTime: new Date(shiftObject.from),
            endDateTime: new Date(shiftObject.to),
          },
        ])
      } else {
        setShifts({
          ...shiftObject,
          startDateTime: new Date(shiftObject.from),
          endDateTime: new Date(shiftObject.to),
        })
      }
    }
  }

  const getPossibleOverlappingShifts = targetShift => {
    const targetDate = moment(targetShift.from)

    const possibleOverlappingShifts =
      shifts &&
      shifts.length &&
      shifts.filter(shift => {
        if (
          shift.from &&
          shift.id !== targetShift.id &&
          viewCookie === 'dayGridMonth'
        ) {
          const areDatesSame = targetDate.isSame(moment(shift.from), 'date')
          if (areDatesSame) return true
        } else {
          if (shift.from && !shift.to && shift.id !== targetShift.id) {
            const areDatesSame = targetDate.isSame(moment(shift.from), 'date')
            if (areDatesSame) return true
          }
          if (shift.from && shift.to && shift.id !== targetShift.id) {
            const range = moment().range(moment(shift.from), moment(shift.to))
            const isTargetDateInBetween = range.contains(targetDate)
            if (isTargetDateInBetween) return true
          }
        }
        return false
      })

    return possibleOverlappingShifts
  }

  const eventRender = renderEvent => {
    // NOTE: prevent modifying background event
    const view_height = document.querySelector('.schedule-dashboard')
      .offsetHeight
    setView(view_height)
    if (renderEvent.event.rendering === 'background')
      return renderEvent.event.el

    const {
      shiftStat,
      employees,
      isMinimumMatch,
    } = renderEvent.event.extendedProps
    const employeeData =
      shiftStat &&
      shiftStat.length &&
      shiftStat.map(item => {
        return item && item.employees && item.employees
      })
    let numOfPunchedInEmployees = 0
    let uniqueNumOfPunchedInEmployees = []
    const totalAssignedEmployees = (employees && employees.length) || 0
    employeeData &&
      employeeData.length &&
      employeeData.length > 0 &&
      employeeData.forEach(empArray => {
        if (empArray && empArray.length) {
          empArray.map(emp => {
            if (emp.status != 'absent_punch_in') {
              if (
                new Date(emp.date).getTime() ===
                new Date(renderEvent.event.start).getTime()
              ) {
                if (
                  uniqueNumOfPunchedInEmployees.indexOf(emp.employee_id) == -1
                ) {
                  numOfPunchedInEmployees++
                  uniqueNumOfPunchedInEmployees.push(emp.employee_id)
                }
              }
            }
          })
        }
      })

    const div = document.createElement('div')
    const nameDiv = document.createElement('div')
    const tooltip = document.createElement('div')
    const minEmployeeDot = document.createElement('span')
    div.setAttribute(
      'style',
      `
          display:flex;
          margin: 5px;
          flex-direction: column
        `,
    )
    div.setAttribute(
      'class',
      `Description
        `,
    )

    nameDiv.setAttribute(
      'style',
      `
          display:flex;
          margin: 5px 0;
          flex-direction: column
        `,
    )
    nameDiv.setAttribute(
      'class',
      `sub-description
        `,
    )
    minEmployeeDot.setAttribute(
      'class',
      `sub-employeeDot
        `,
    )

    tooltip.setAttribute(
      'class',
      `tooltip_desc
        `,
    )
    let uniqueEmployees = []
    //code to show red dot when isMinimumMatch would be false.
    if (isMinimumMatch == 'showEmployeeDot') {
      const isEmployeeDot = document.createElement('span')
      isEmployeeDot.innerHTML = `<span class="shift-dot"/>`
      minEmployeeDot.appendChild(isEmployeeDot)
      renderEvent.el.firstChild.prepend(minEmployeeDot) // prepend the dot to shift time.
    }

    tooltip.innerHTML = renderEvent.event.title
    // if (employeeData  && employeeData.length) {
    if (employeeData && employeeData.length) {
      div.innerHTML =
        numOfPunchedInEmployees + ' of ' + totalAssignedEmployees + ' Reported'
      //storing the punched in employees id to remove the duplicates entry
      employeeData &&
        employeeData.length &&
        employeeData.length > 0 &&
        employeeData.forEach(item => {
          item &&
            item.length &&
            item.forEach(emp => {
              if (
                new Date(emp.date).getTime() ===
                new Date(renderEvent.event.start).getTime()
              ) {
                uniqueEmployees.push(emp.employee_id)
              }
            })
        })

      employeeData &&
        employeeData.length &&
        employeeData.length > 0 &&
        employeeData.forEach(item => {
          item &&
            item.length &&
            item.forEach(emp => {
              if (
                new Date(emp.date).getTime() ===
                new Date(renderEvent.event.start).getTime()
              ) {
                const empDiv = document.createElement('div')
                empDiv.innerHTML = `
                <div class="emp-tag" style="background: ${
                  emp.extra_data.display_color
                }"><span class="tick-icon" />
                  <span class="emp-name">${emp.employee_name ||
                    emp.employee_full_name}</span>
                  <span class="ptime ${emp.status}">
                  ${emp.punchin_tz ? emp.punchin_tz : ''}
                </span></div>`
                nameDiv.appendChild(empDiv)
              } else if (
                (!emp.extra_data.date_tz && emp.status == 'absent_punch_in') ||
                (emp.status != 'absent_punch_in' &&
                  new Date(emp.date).getTime() !==
                    new Date(renderEvent.event.start).getTime() &&
                  uniqueEmployees.indexOf(emp.employee_id) == -1)
              ) {
                uniqueEmployees.push(emp.employee_id)
                const empDiv = document.createElement('div')
                empDiv.innerHTML = `
                  <div class="emp-tag absent_punch_in"><span class="cross-icon" />
                    <span class="emp-name">${emp.employee_name ||
                      emp.employee_full_name}</span>
                  </div>`
                nameDiv.appendChild(empDiv)
              }
            })
        })
    } else {
      div.innerHTML = '0' + ' of ' + totalAssignedEmployees + ' Reported'
      employees &&
        employees.length &&
        employees.length > 0 &&
        employees.forEach(item => {
          defaultEmployees &&
            defaultEmployees.length &&
            defaultEmployees.length > 0 &&
            defaultEmployees.forEach(emp => {
              if (emp.id === item) {
                const empDiv = document.createElement('div')
                empDiv.innerHTML = `
                  <div class="emp-tag absent_punch_in"><span class="cross-icon" />
                    <span class="emp-name">${emp.user.full_name}</span>
                  </div>`
                nameDiv.appendChild(empDiv)
              }
            })
        })
    }
    renderEvent.el.firstChild.appendChild(div)
    renderEvent.el.firstChild.appendChild(nameDiv)
    renderEvent.el.appendChild(tooltip)
    return renderEvent.el
  }

  // this method is triggered when we select few dates/time on calendar
  const select = selectionInfo => {
    let {start, end} = selectionInfo

    // NOTE: prevent drag event creation on past dates
    const isPastEvent = moment(Date.now()).isAfter(start)
    if (isPastEvent) {
      setToastData({
        visible: true,
        text: messages.PAST_DATE_SELECT_TOAST_MESSAGE,
        background: 'accent-2',
      })
      setTimeout(
        () => setToastData({visible: false, text: '', background: 'accent-1'}),
        3000,
      )
      return
    }
    // TODO: unselect the selection

    setClickedDateData({from: start, to: end})
  }
  //const datesRender = (info) => {}
  const datesRender = info => {
    const view_height = document.querySelector('.schedule-dashboard')
      .offsetHeight
    const position = cookies.get('position')
    document.getElementsByClassName('fc-view-container')[0].scrollTop = position
      setView(view_height);
    const query = queryString.parse(search)
    if (singleton.oAuthToken === null) {
      singleton.oAuthToken = query.oAuthToken
    }
    if (
      startDate != moment(info.view.currentStart).format('YYYY-MM-DD') ||
      endDate != moment(info.view.currentEnd).format('YYYY-MM-DD')
    ) {
      if (info.view.type !== viewCookie) {
        startDate = moment().format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
        setShifts([])
        startDate && setInitialViewDate(startDate)
      } else {
        startDate = moment(info.view.currentStart).format('YYYY-MM-DD')
        endDate = moment(info.view.currentEnd).format('YYYY-MM-DD')
        setShifts([])
        startDate && setInitialViewDate(startDate)
      }
      //if employee from the employee list is selected to show only its shifts
      let newStartDate = info.view.type.includes('Month')
        ? moment(startDate)
            .subtract(1, 'week')
            .format('YYYY-MM-DD')
        : moment(startDate).format('YYYY-MM-DD')
      let newEndDate = info.view.type.includes('Month')
        ? moment(endDate)
            .add(2, 'week')
            .format('YYYY-MM-DD')
        : moment(endDate).format('YYYY-MM-DD')

      if (employeeOfShift) {
        // fetchAllShifts(eventEmitter,{
        debounceFetchAllShifts(eventEmitter, {
          paginate: false,
          start_date: newStartDate,
          end_date: newEndDate,
          employee_id: employeeOfShift,
        })
      } else {
        //fetchAllShifts(eventEmitter, {
        debounceFetchAllShifts(eventEmitter, {
          paginate: false,
          start_date: newStartDate,
          end_date: newEndDate,
        })
      }
    }
  }

  const editRecurring = (type, shift, oldShift) => {
    setRecurringShiftModalOpen(type)
    setRecurringShift(shift)
    setOldShiftData(oldShift)
  }

  const editRecurringShiftDetails = (type, shift) => {
    setRecurringShiftModalOpen(type)
    setShiftDetailsEdit(type)
    setRecurringShift(shift)
  }

  const removeEmployeeFromRecurringShift = (type, employeeId) => {
    setRecurringShiftModalOpen(type)
    setShiftDetailsEdit(type)
    setRecurringEmployeeId(employeeId)
  }

  const addEmployeeToRecurringShift = (type, shiftId, optionId, shiftDate) => {
    setRecurringShiftModalOpen(type)
    setShiftDetailsEdit(type)
    setRecurringShiftId(shiftId)
    setRecurringOptionId(optionId)
    setRecurringShiftDate(shiftDate)
  }

  const deleteRecurringShift = type => {
    setRecurringShiftModalOpen(type)
    setShiftDetailsEdit(type)
  }

  const editRecurringShift = () => {
    setSubmitShift({isSavingUpcoming: true})
    let type = RecurringShiftModalOpen
    let shift = RecurringShift
    let oldShift = oldShiftData
    let recurringEmployee = recurringEmployeeId
    let recurringShiftID = recurringShiftId
    let recurringOptionID = recurringOptionId
    let recurringShiftStartDate = recurringShiftDate
    if (type == 'resize') {
      const deviation_data =
        shift.deviationThreshold &&
        (shift.deviationThreshold.punch_in_before !== null ||
          shift.deviationThreshold.punch_in_after !== null)
          ? {
              punch_in_before: shift.deviationThreshold.punch_in_before || 0,
              punch_in_after: shift.deviationThreshold.punch_in_after || 0,
            }
          : null
      updateDragShift({
        id: shift.id,
        name: shift.name,
        no_of_employees: shift.expectedEmployees,
        restriction_data: {punch_in: shift.punchInRestrictions || null},
        deviation_data: deviation_data,
        on_going: shift.reoccuring && (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) ? true : false,
        repeat: {
          weekdays:
            shift.reoccuring &&
            (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) &&
            shift.reoccuring.days,
        },
        from: shift.from,
        to: shift.to,
        end_date:
          shift.reoccuring &&
          shift.reoccuring.endsOn &&
          moment(shift.end_date).format('YYYY-MM-DD'),
        permanent_change: true,
        datetime_change: true,
        from_edit_date: moment.utc(oldShift.from).format('YYYY-MM-DD'),
        timefence_end_time: shift.from,
        timefence_start_time: moment(shift.from)
          .subtract(30, 'minutes')
          .toDate(),
        drag: false,
        never_expire: shift?.reoccuring?.neverExpire || null,
      })
    }

    if (type == 'eventDrop') {
      const deviation_data =
        shift.deviationThreshold &&
        (shift.deviationThreshold.punch_in_before !== null ||
          shift.deviationThreshold.punch_in_after !== null)
          ? {
              punch_in_before: shift.deviationThreshold.punch_in_before || 0,
              punch_in_after: shift.deviationThreshold.punch_in_after || 0,
            }
          : null
      updateDragShift({
        id: shift.id,
        name: shift.name,
        no_of_employees: shift.expectedEmployees,
        restriction_data: {punch_in: shift.punchInRestrictions || null},
        deviation_data: deviation_data,
        on_going: shift.reoccuring && (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) ? true : false,
        repeat: {
          weekdays:
            shift.reoccuring &&
            (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) &&
            shift.reoccuring.days,
        },
        from: shift.from,
        to: shift.to ? shift.to : moment(shift.from).add(1, 'days'),
        end_date:
          shift.reoccuring &&
          shift.reoccuring.endsOn &&
          moment(shift.end_date).format('YYYY-MM-DD'),
        permanent_change: true,
        datetime_change: true,
        from_edit_date: moment.utc(oldShift.from).format('YYYY-MM-DD'),
        timefence_end_time: shift.from,
        timefence_start_time: moment(shift.from)
          .subtract(30, 'minutes')
          .toDate(),
        drag: true,
        never_expire: shift?.reoccuring?.neverExpire || null,
      })
    }
    if (type == 'edit') {
      updateShift({...RecurringShift, permanent_change: true})
    }
    if (type == 'removeEmployee') {
      removeEmployeeFromShift(recurringEmployeeId, 'permanentChange')
    }
    if (type == 'addEmployee') {
      addEmployeeToShift(
        recurringShiftID,
        recurringOptionID,
        recurringShiftStartDate,
        'permanentChange',
      )
    }
    if (type == 'deleteShift') {
      deleteShift('permanentChange')
    }
    if (type == 'dragAddEmployee') {
      addEmployeeToShift(
        recurringShiftID,
        recurringOptionID,
        recurringShiftStartDate,
        'permanentChange',
        'dragAddEmployee',
      )
    }
    setSelectedEmployees([])
  }

  const currentRecurringShiftEditModal = () => {
    let type = RecurringShiftModalOpen
    let shift = RecurringShift
    let oldShift = oldShiftData
    let recurringShiftID = recurringShiftId
    let recurringOptionID = recurringOptionId
    let recurringShiftStartDate = recurringShiftDate
    let recurringEmployee = recurringEmployeeId
    setSubmitShift({isSavingOnly: true})
    if (type == 'resize') {
      const deviation_data =
        shift.deviationThreshold &&
        (shift.deviationThreshold.punch_in_before !== null ||
          shift.deviationThreshold.punch_in_after !== null)
          ? {
              punch_in_before: shift.deviationThreshold.punch_in_before || 0,
              punch_in_after: shift.deviationThreshold.punch_in_after || 0,
            }
          : null
      updateDragShift({
        id: shift.id,
        name: shift.name,
        no_of_employees: shift.expectedEmployees,
        restriction_data: {punch_in: shift.punchInRestrictions || null},
        deviation_data: deviation_data,
        on_going: shift.reoccuring && (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) ? true : false,
        repeat: {
          weekdays:
            shift.reoccuring &&
            (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) &&
            shift.reoccuring.days,
        },
        from: shift.from,
        to: shift.to,
        end_date:
          shift.reoccuring &&
          shift.reoccuring.endsOn &&
          moment(shift.end_date).format('YYYY-MM-DD'),
        datetime_change: true,
        from_edit_date: moment.utc(oldShift.from).format('YYYY-MM-DD'),
        timefence_end_time: shift.from,
        timefence_start_time: moment(shift.from)
          .subtract(30, 'minutes')
          .toDate(),
        drag: false,
        never_expire: shift?.reoccuring?.neverExpire || null,
      })
    }

    if (type == 'eventDrop') {
      const deviation_data =
        shift.deviationThreshold &&
        (shift.deviationThreshold.punch_in_before !== null ||
          shift.deviationThreshold.punch_in_after !== null)
          ? {
              punch_in_before: shift.deviationThreshold.punch_in_before || 0,
              punch_in_after: shift.deviationThreshold.punch_in_after || 0,
            }
          : null
      updateDragShift({
        id: shift.id,
        name: shift.name,
        no_of_employees: shift.expectedEmployees,
        restriction_data: {punch_in: shift.punchInRestrictions || null},
        deviation_data: deviation_data,
        on_going: shift.reoccuring && (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) ? true : false,
        repeat: {
          weekdays:
            shift.reoccuring &&
            (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) &&
            shift.reoccuring.days,
        },
        from: shift.from,
        to: shift.to ? shift.to : moment(shift.from).add(1, 'days'),
        end_date:
          shift.reoccuring &&
          shift.reoccuring.endsOn &&
          moment(shift.end_date).format('YYYY-MM-DD'),
        datetime_change: true,
        from_edit_date: moment.utc(oldShift.from).format('YYYY-MM-DD'),
        timefence_end_time: shift.from,
        timefence_start_time: moment(shift.from).subtract(30, 'minutes'),
        drag: true,
        never_expire: shift?.reoccuring?.neverExpire || null,
      })
    }
    if (type == 'edit') {
      updateShift(RecurringShift)
    }
    if (type == 'removeEmployee') {
      removeEmployeeFromShift(recurringEmployeeId)
    }
    if (type == 'addEmployee') {
      addEmployeeToShift(
        recurringShiftID,
        recurringOptionID,
        recurringShiftStartDate,
        false,
      )
    }
    if (type == 'deleteShift') {
      deleteShift()
    }
    if (type == 'dragAddEmployee') {
      addEmployeeToShift(
        recurringShiftID,
        recurringOptionID,
        recurringShiftStartDate,
        false,
        'dragAddEmployee',
      )
    }
    setSelectedEmployees([])
  }

  //Set the null in shiftStartDate and ShiftEndDate on modal close of shift modal,shiftAddEditModal,Update Shift and delte Shift
  const setShiftStartEndDateNull = () => {
    shiftStartDate = null
    shiftEndDate = null
  }

  //Note: fetchAllShits will not emit show loader event when doNotShowloader contains some value.
  const handleRefresh = doNotShowLoader => {
    let newStartDate = viewCookie?.includes('Month')
      ? moment(startDate)
          .subtract(1, 'week')
          .format('YYYY-MM-DD')
      : moment(startDate).format('YYYY-MM-DD')
    let newEndDate = viewCookie?.includes('Month')
      ? moment(endDate)
          .add(2, 'week')
          .format('YYYY-MM-DD')
      : moment(endDate).format('YYYY-MM-DD')
    fetchAllShifts(
      eventEmitter,
      {
        paginate: false,
        start_date: newStartDate,
        end_date: newEndDate,
      },
      doNotShowLoader,
    )
  }

  //Getting the saved cookie and send this to defaultView of FullCalendar to retain selected view
  const viewCookie = cookies.get('viewType')


  return (
    <Box
      pad={{left: 'medium', right: 'medium'}}
      margin={{right: 'small'}}
      flex={{grow: 1}}
    >
      {loader.isShiftLoading && (
        <Box
          align="center"
          justify="center"
          style={{
            position: 'absolute',
            'z-index': '35',
            left: '58%',
            top: '55%',
          }}
        >
          <Spinner style={{width: '40px', height: '40px'}} />
        </Box>
      )}
      <Box
        alignSelf="end"
        direction="row"
        pad={{bottom: 'medium'}}
      >
        <Box
          border={{color: 'light-4', size: 'xsmall'}}
          style={{background: 'white', minWidth: '11.5rem'}}
          round="xsmall"
          pad="xxsmall"
          gap="large"
          margin={{right: '0.3rem'}}
        >
          <DateTimePicker
            initialDate={initialViewDate}
            onClose={e => setInitialViewDate(e.date)}
            closeOnClick={true}
            date_format={date_format}
            shiftManagement="shiftManagement"
          />
        </Box>
        <Box gap="medium">
          {
            <Button
              alignSelf="end"
              pad="xxsmall"
              icon={
                <Image
                  src={process.env.PUBLIC_URL + '/img/dash-icon.png'}
                  alt="icon"
                  height="20"
                  className="valign-btm"
                  onClick={() => {
                    window.parent.postMessage(
                      JSON.stringify({message: 'SHIFT_DASHBOARD_PAGE'}),
                      '*',
                    )
                  }}
                />
              }
              color="accent-1"
              primary
            />
          }
        </Box>
      </Box>
      <Grid
        rows={['full']}
        columns={['17%', '83%']}
        gap="xsmall"
        areas={[
          {name: 'filter', start: [0, 0], end: [0, 0]},
          {name: 'content', start: [1, 0], end: [1, 0]},
        ]}
      >
        {
          <Box gridArea="filter">
            <Filters
              departments={departments}
              defaultTeams={defaultTeams}
              employees={defaultEmployees}
              defaultShifts={shifts}
              setShifts={setShifts}
              externalEventsContainerRef={externalEventsContainerRef}
              eventEmitter={eventEmitter}
              getEmployeeShifts={getEmployeeShifts}
              view={view}
              onDepartmentSelect={onDepartmentSelect}
              onTeamSelect={onTeamSelect}
            />
          </Box>
        }
        <Box
          background="light-1"
          pad="16px"
          gridArea="content"
          className="schedule-dashboard"
        >
          <FullCalendar
            employees={defaultEmployees}
            shifts={shifts}
            setShifts={setShifts}
            mapEventToShift={mapEventToShift}
            updateDragShift={updateDragShift}
            fullcalendarConfig={{
              defaultView: viewCookie ? viewCookie : 'timeGridWeek',
              header: {
                left: 'prev',
                center: 'today title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek, next',
              },
              eventClick,
              eventReceive,
              eventRender,
              eventDurationEditable: true,
              editable: true,
              droppable: true,
              eventStartEditable: true,
              plugins: [
                'dayGridPlugin',
                'timeGridPlugin',
                'interactionPlugin',
                'momentPlugin',
              ],
              selectable: true,
              select,
              navLinks: true,
              datesRender,
            }}
            selectedDate={initialViewDate}
            editRecurring={editRecurring}
            revertFunc={revertFunc}
            setRevert={setRevert}
            date_format={date_format}
            shiftManagement
          />
          {shiftModalData.shiftId && (
            <ShiftModal
              layer={layer}
              setLayer={setLayer}
              selectEmployee={selectEmployee}
              setselectEmployee={setselectEmployee}
              data={shiftModalData}
              setData={setShiftModalData}
              messages={messages}
              removeEmployee={removeEmployeeFromShift}
              addEmployee={addEmployeeToShift}
              setShiftEditData={setClickedDateData}
              shifts={shifts}
              employees={defaultEmployees}
              deleteShift={deleteShift}
              shiftData={shiftData}
              employeesData={employeesData}
              date_format={date_format}
              time_format_24_hr={time_format_24_hr}
              shiftStartDate={shiftStartDate}
              shiftEndDate={shiftEndDate}
              setShiftDataForRecurringShift={setShiftDataForRecurringShift}
              setShiftData={setShiftData}
              removeEmployeeFromRecurringShift={
                removeEmployeeFromRecurringShift
              }
              addEmployeeToRecurringShift={addEmployeeToRecurringShift}
              isSubmitting={submitShift.isSubmitting}
              isEditing={submitShift.isEditing}
              setSubmitShift={setSubmitShift}
              setShiftStartEndDateNull={setShiftStartEndDateNull}
              isAssignButtonLoading={isAssignButtonLoading}
              shiftManagement
              selectedEmployees={selectedEmployees}
              setSelectedEmployees={setSelectedEmployees}
            />
          )}
          {clickedDateData.from && (
            <ShiftAddEditModal
              messages={messages}
              data={clickedDateData}
              setData={setClickedDateData}
              createShift={createShift}
              updateShift={updateShift}
              editMode={Boolean(clickedDateData.name)}
              editRecurring={editRecurring}
              setShiftId={setShiftModalData}
              editRecurringShiftDetails={editRecurringShiftDetails}
              time_format_24_hr={time_format_24_hr}
              date_format={date_format}
              isSubmitting={submitShift.isSubmitting}
              setSubmitShift={setSubmitShift}
              shiftStartDate={shiftStartDate}
              setShiftStartEndDateNull={setShiftStartEndDateNull}
              shiftEndDate={shiftEndDate}
              shiftData={shiftData}
            />
          )}
          {layer && (
            <ShiftDeleteModal
              layer={layer}
              setLayer={setLayer}
              deleteShift={deleteShift}
              isSubmitting={submitShift.isSubmitting}
              isDeleting={submitShift.isDeleting}
              setSubmitShift={setSubmitShift}
              deleteRecurringShift={deleteRecurringShift}
              shiftData={shiftData}
            />
          )}
          {selectEmployee && (
            <EmployeeRemoveModal
              selectEmployee={selectEmployee}
              setselectEmployee={setselectEmployee}
              removeEmployeeFromShift={removeEmployeeFromShift}
              isDeleting={submitShift.isDeleting}
            />
          )}
          {RecurringShiftModalOpen && (
            <RecurringShiftEditModal
              RecurringShiftModalOpen={RecurringShiftModalOpen}
              setRecurringShiftModalOpen={setRecurringShiftModalOpen}
              editRecurringShift={editRecurringShift}
              currentRecurringShiftEditModal={currentRecurringShiftEditModal}
              setRevert={setRevert}
              shiftDetailsEdit={shiftDetailsEdit}
              isSavingOnly={submitShift.isSavingOnly}
              isSavingUpcoming={submitShift.isSavingUpcoming}
              setSubmitShift={setSubmitShift}
              data={shiftData}
              RecurringShift={RecurringShift}
            />
          )}
          {overlappingShiftsData.shift && (
            <ShiftMergeModal
              messages={messages}
              data={overlappingShiftsData}
              setData={setOverlappingShiftsData}
              addEmployee={addEmployeeToShift}
              saveShift={saveShift}
              addEmployeeToRecurringShift={addEmployeeToRecurringShift}
              shiftData={shiftData}
            />
          )}
          {toastData.visible && (
            <Toast
              onClose={() =>
                setToastData({visible: false, text: '', background: 'accent-1'})
              }
              text={toastData.text}
              background={toastData.background}
            />
          )}
        </Box>
      </Grid>
    </Box>
  )
}

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
})

export default connect(mapStateToProps)(ShiftManagementPage)
